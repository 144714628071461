<template>
  <HandleDataForms :api-source="mongo" :data-source="database" title="Formulario Matricula" collection="form_matricula" template-table="TableMatricula" template-table-modal="TableMatriculaModal" :data-excel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const database = computed(() => store.getters.getProduccion)
    // eslint-disable-next-line no-unused-vars
    const mongo = computed(() => store.getters.getMongoFeriasURL)
    // const mongo = 'https://api.idastage.com/api/'

    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))


    let dataExcel = {
      fields: {
        page_id: 'PID',
        fecha: 'Fecha',
        tipo_documento: 'Tipo identificacion',
        rut: 'RUT',
        pasaporte: 'Pasaporte',
        nombres: 'Nombre',
        apellidos: 'Apellido',
        email: 'Email',
        codigo_celular: 'Codigo Celular',
        celular: 'Celular',
        institucion: "Institucion",
        sede: "Sede",
        carrera: "Carrera",
        cedula_alumno: "Cedula Alumno",
        cedula_apoderado: "Cedula Apoderado",
        contrato: "Contrato"
      },
      query: {
        collection: 'form_matricula',
        date_field: 'createdAt',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
